<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-md-right">
              <router-link :to="{ name: 'admin.plans.create' }" class="btn btn-primary btn-sm mr-4">
                <i class="uil uil-plus"></i> New Plan
              </router-link>
            </div>
            <template v-if="!loadingPlans">
              <div class="table-responsive mb-0">
                <b-table :items="plans" :fields="datatable.columns" :per-page="datatable.queries.per_page"
                  :current-page="datatable.queries.page">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(name)="data">
                    <span v-b-tooltip.hover v-b-tooltip.hover.right :title="data.item.desc">{{ data.item.name }}</span>
                  </template>
                  <template v-slot:cell(interval)="data">
                    <span class="text-capitalize">{{ data.item.interval }}ly</span>
                  </template>
                  <template v-slot:cell(features)="data">
                    <p class="mb-1">
                      Number of Businesses: <b>{{ getFeatureValueByKey(data.item, 'Number of Businesses') }}</b>
                    </p>
                    <span class="cursor-pointer" style="font-size:17px" @click="showMoreDetail(data.item)"><i class="uil uil-question-circle"></i></span>
                  </template>
                  <template v-slot:cell(salesplan)="data">
                    <span>
                      {{ data.item.sale_plan }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-button variant="primary" size="sm" title="Link" class="ml-1" @click="showLink(data.item)">
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-copy"></i>
                    </b-button>
                    <router-link :to="{
                      name: 'admin.plans.edit',
                      params: { id: data.item.id },
                    }" class="btn btn-success btn-sm ml-1">
                      <i class="uil uil-edit"></i>
                    </router-link>
                    <b-button v-if="false" variant="danger" size="sm" title="Delete" class="ml-1"
                      :disabled="loadingRemove === data.item.id" @click="remove(data.item)">
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(visible)="data">
                    <feather v-if="data.item.visible" type="check" class="icon-dual-success" />
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="datatable.queries.page" :total-rows="plans.length"
                        :per-page="datatable.queries.per_page"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete plan">
      <p v-if="selectedPlan">Are you sure you want to delete plan "{{ selectedPlan.name }}"?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false">Cancel</b-button>
        <b-button variant="danger" @click="handleDelete">Delete</b-button>
      </template>
    </b-modal>
    <b-modal v-model="showScriptModal" title="Script" size="lg">
      <h3>Here's the order page link for the select plan.</h3>
      <div>
        <textarea ref="linkText" v-model="orderLink" name="code" cols="30" rows="6" class="form-control" readonly
          @click="copyLink"></textarea>
        <div class="text-right mt-1">
          <a href="javascript:void(0)" @click="copyLink">
            Copy the code
          </a>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="copyLink"><i class="uil uil-copy"></i> Copy</b-button>
        <b-button variant="light" @click="showScriptModal = false">Cancel</b-button>
      </template>
    </b-modal>
    <b-modal v-model="showDetailModal" title="Plan Details">
      <div v-if="selectedPlan" class="d-block">
        <h5 class="text-primary">{{ selectedPlan.name }}</h5>
        <p class="mb-3">( {{ selectedPlan.id }} )</p>
        <div v-for="item of selectedPlan.features" :key="item.key">
          <template v-if="['Sales Plan', 'Default Plan'].includes(item.key)">
            <p v-if="item.value" class="mb-1"
              :class="{ 'text-primary font-italic': item.key === 'Default Plan' }">
              <b>{{ item.key }}: {{ item.value }}</b>
            </p>
          </template>
          <p v-else class="mb-1">
            {{ item.key }}: <b>{{ item.value }}</b>
          </p>
        </div>
        <div v-if="selectedPlan.has_upfront">
          <p class="mb-1">
            Upfront: <b>${{ selectedPlan.upfront_price }}</b>
          </p>
        </div>
        <div class="mt-3">
          <p class="mb-1">Free Numbers: <b>{{ selectedPlan.free_numbers }}</b></p>
          <p class="mb-1">Free Messages: <b>{{ selectedPlan.free_messages }}</b></p>
          <p class="mb-1">Free Calls: <b>{{ selectedPlan.free_calls }}</b></p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDetailModal = false">Close</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'name', sortable: true },
          { key: 'amount', label: 'Price', sortable: true },
          { key: 'interval', label: 'Interval', sortable: false },
          { label: 'Features', key: 'features' },
          { label: 'Sales Plan', key: 'salesplan' },
          { label: 'Visible', key: 'visible' },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
        },
      },
      showDeleteModal: false,
      showDetailModal: false,
      selectedPlan: null,
      loadingPlans: false,
      loadingImpersonate: false,
      loadingRemove: null,
      showScriptModal: false,
      orderLink: '',
    }
  },

  computed: {
    plans() {
      let plans = this.$store.getters['subscription/allPlans'] || []

      plans.sort((a, b) => (a.created - b.created) * -1)

      return plans
    },
  },

  created() {
    this.$store.dispatch('agency/setCurrentPage', 'index')
  },

  mounted() {
    if (!this.$store.getters['subscription/allPlans']) {
      this.getPlans()
    }
  },

  methods: {
    showMoreDetail(plan) {
      this.selectedPlan = plan
      this.showDetailModal = true
    },
    getFeatureValueByKey(data, key) {
      let res = data.features.find(item => item.key === key)
      if (res)
        return res.value
      return 'N/A'
    },
    showLink(plan) {
      this.orderLink = `${location.origin}/order?plan=${plan.id}`;
      this.showScriptModal = true
    },

    copyLink() {
      this.$refs.linkText.select()
      this.$refs.linkText.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    getPlans() {
      if (this.$store.getters['subscription/allPlans']) return

      this.loadingPlans = true
      this.$store
        .dispatch('subscription/getAllPlans')
        .then(() => {
          this.loadingPlans = false
        })
        .catch(() => {
          this.loadingPlans = false
        })
    },

    remove(plan) {
      this.showDeleteModal = true
      this.selectedPlan = plan
    },

    handleDelete() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedPlan.id
      this.$store
        .dispatch('subscription/deletePlan', this.selectedPlan.id)
        .then(() => {
          this.loadingRemove = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
